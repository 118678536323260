import IMask from 'imask'
import Splide from '@splidejs/splide'

function maskInputs() {
    document.querySelectorAll('input.phone-mask').forEach(input => {
        IMask(input, {
            mask: '(00) 00000-0000'
        })
    })
    document.querySelectorAll('input.date-mask').forEach(input => {
        IMask(input, {
            mask: Date,
            pattern: 'd{/}`m{/}`Y',
            format: date => {
                let day = date.getDate();
                let month = date.getMonth() + 1;
                const year = date.getFullYear();
                if (day < 10) day = "0" + day;
                if (month < 10) month = "0" + month;
                return [day, month, year].join('/');
              },
              parse: str => {
                const yearMonthDay = str.split('/');
                return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0]);
              },
              autofix: true,
              max: new Date()
          })
    })
}

function setCarousels() {
    new Splide( '.levels-carousel', {
        type   : 'slide',
        fixedWidth: '330px',
        gap: '30px',
        padding: '30px',
        focus: 'center',
        arrows: false,
        pagination: false,
        mediaQuery: 'min',
        breakpoints: {
            1100: {
                drag: false,
            },
        },
    }).mount();

    new Splide( '.reasons-carousel', {
        type   : 'slide',
        fixedWidth: '260px',
        gap: '30px',
        padding: '30px',
        focus: 'center',
        arrows: false,
        pagination: false,
        mediaQuery: 'min',
        breakpoints: {
            1100: {
                drag: false,
            },
            1400: {
                fixedWidth: false,
                perPage: 5,
            },
        },
    }).mount();
}

function formSubmit() {
    document.querySelectorAll('form').forEach(form => {
        form.addEventListener('submit', function (event) {
            event.preventDefault()
            const whatsappMessage = `Olá, gostaria de receber mais informaçòes.`;
            const whatsappUrl = `https://wa.me/+5511940311105?text=${encodeURIComponent(whatsappMessage)}`;

            fetch('formulario/index.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(new FormData(form)),
            })
                .then(response => response.json())
                .then(data => {
                    const messageEl = form.querySelector('.message')

                    if (confirm(data.message + "\n\nDeseja também entrar em contato via WhatsApp?") === true) {
                        window.open(whatsappUrl, '_blank');
                    }

                    if (data.status === 'success') {
                        messageEl.classList.remove('error')
                        const url = window.location.protocol + "//" + window.location.host + window.location.pathname + '?g=obrigado';
                        window.location = url
                    } else {
                        messageEl.textContent = data.message
                        messageEl.classList.add('error')
                    }
                })
                .catch((error) => {
                    console.error('Error:', error)
                })
        })
    })
}

function handleThankYou() {
	const urlParams = new URLSearchParams(window.location.search)
	const paramValue = urlParams.get('g')
	if (paramValue === 'obrigado') {
		document.querySelectorAll('.message').forEach(element => {
            element.classList.add('success')
            element.innerText = 'Obrigado por enviar suas informações, em breve entraremos em contato.'
        })
	}
}

handleThankYou()
setCarousels()
formSubmit()
maskInputs()